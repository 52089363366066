<!--
 * @Description: 编辑前端类目
 * @Author: 琢磨先生
 * @Date: 2022-05-13 11:43:06
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-06-15 22:24:39
-->

<template>
  <el-dialog
    v-model="drawer"
    :title="title"
    width="800px"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="父级" prop="father_id">
        <el-select v-model="form.father_id" placeholder="" clearable>
          <el-option
            v-for="item in groups"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="名称" prop="name">
        <el-col :span="14">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="停用">
        <el-switch v-model="form.is_stop"></el-switch>
      </el-form-item>
      <el-form-item label="icon">
        <div style="width: 100%">
          <upload-avatar
            :src="form.icon"
            :aspectRatio="1"
            @change="fileChange"
          ></upload-avatar>
        </div>
        <div class="help-block">icon图标要求为1:1比例图片，且宽高不超过400</div>
      </el-form-item>

      <el-form-item label="序号(正序)">
        <el-col :span="10">
          <el-input v-model="form.sequence" placeholder=""></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="关联类目" class="is-required" v-if="form.father_id">
        <multiple-category @change="checkedCategory"></multiple-category>
        <el-alert
          type="info"
          v-if="categoryList.length > 0"
          :closable="false"
          style="margin-top: 10px"
        >
          <el-tag
            v-for="(item, i) in categoryList"
            :key="item.value"
            closable
            @close="removeCategory(i)"
            style="margin-right: 20px"
            >{{ item.full_name }}</el-tag
          >
        </el-alert>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSubmit" :loading="saving"
          >确定</el-button
        >
        <el-button @click="cancelClick" :disabled="saving">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import UploadAvatar from "@/views/upload/upload_avatar.vue";
import MultipleCategory from "../multiple_category.vue";

export default {
  components: {
    UploadAvatar,
    MultipleCategory,
  },
  data() {
    return {
      drawer: false,
      saving: false,
      title: "编辑",
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
      },
      //选择的类目列表
      categoryList: [],
    };
  },
  props: ["item", "groups", "reload"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.drawer = true;
          this.form = Object.assign({}, this.item);
          if (this.form.id) {
            this.categoryList = this.form.categories.map((item) => {
              return {
                value: item["id"],
                label: item["name"],
                leaf: item["is_leaf"],
                full_name: item["full_name"],
              };
            });
          }
        }
      },
      immediate: true,
    },
  },

  methods: {
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("/admin/v1/category/front/edit", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.drawer = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                if (this.reload) {
                  this.reload();
                }
              }
              this.saving = false;
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },
    /**
     * 取消
     */
    cancelClick() {
      this.drawer = false;
      if (this.reload) {
        this.reload();
      }
    },

    /**
     *  文件上传成功后返回
     */
    fileChange(url) {
      this.form.icon = url;
    },
    /**
     * 选择的类目
     */
    checkedCategory(values) {
      console.log(values);
      values.forEach((item) => {
        if (this.categoryList.findIndex((o) => o.value == item.value) == -1) {
          this.categoryList.push(item);
        }
      });
      this.form.cate_ids = this.categoryList.map((x) => x.value);
    },
    /**
     * 移除
     * @param {*} i
     */
    removeCategory(i) {
      this.categoryList.splice(i, 1);
      this.form.cate_ids = this.categoryList.map((x) => x.value);
    },
  },
};
</script>  