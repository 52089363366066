<!--
 * @Description: 选择类目弹框
 * @Author: 琢磨先生
 * @Date: 2022-06-06 21:54:10
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-06-15 03:17:57
-->

<template>
  <el-button @click="openDialog" size="small">选择类目</el-button>
  <el-dialog
    title="选择类目"
    v-model="visible"
    width="1000px"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-select
      class="cate-keyword"
      size="large"
      v-model="value"
      placeholder="输入关键字查询类目"
      remote
      filterable
      clearable
      @change="selectChange"
      :remote-method="searchCategory"
    >
      <el-option
        v-for="item in arrayList"
        :key="item.id"
        :label="item.full_name"
        :value="item.id"
      >
        <div
          v-html="item.full_name.replace(query.q, `<em>${query.q}</em>`)"
        ></div>
      </el-option>
    </el-select>

    <el-cascader-panel
      ref="cascader"
      :props="props"
      clearable
      @change="changeCategory"
    />

    <el-alert
      type="info"
      :closable="false"
      title="已选择："
      style="margin-top: 20px"
    >
      <el-tag
        v-for="item in checkedNodes"
        :key="item.value"
        style="margin-right: 20px"
        >{{ item.data.full_name }}</el-tag
      >
      <el-tag
        v-for="(item, i) in selectedList"
        :key="item.id"
        style="margin-right: 20px"
        closable
        @close="selectedList.splice(i, 1)"
        >{{ item.full_name }}</el-tag
      >
    </el-alert>

    <template #footer>
      <el-button type="primary" size="default" @click="onConfirm"
        >确定</el-button
      >
      <el-button @click="onCancel">取消</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    var that = this;
    return {
      visible: false,
      //选中的值
      checkedNodes: [],
      //下拉框
      arrayList: [],
      value: "",
      query: {
        q: "",
      },
      //下拉框选中的节点
      selectedList: [],
      props: {
        emitPath: false,
        multiple: true,
        checkStrictly: true,
        lazy: true,
        lazyLoad(node, resolve) {
          console.log(node);
          that.$http
            .get(
              `admin/v1/common/categories?fatherId=${
                node.value ? node.value : 0
              }&q=`
            )
            .then((res) => {
              if (res.code == 0) {
                var nodes = res.data.map((item) => {
                  return {
                    value: item["id"],
                    label: item["name"],
                    leaf: item["is_leaf"],
                    full_name: item["full_name"],
                  };
                });
                resolve(nodes);
              }
            });
        },
      },
    };
  },
  emits: ["change"],
  methods: {
    /**
     * 下拉框搜索
     */
    searchCategory(q) {
      this.query.q = q;
      this.$http.get("admin/v1/common/select/category?q=" + q).then((res) => {
        if (res.code == 0) {
          this.arrayList = res.data;
        }
      });
    },
    /**
     * 下拉框选择更改
     * @param {*} item
     */
    selectChange(value) {
      if (value) {
        if (this.selectedList.findIndex((o) => o.id == value) == -1) {
          var item = this.arrayList.find((x) => x.id == value);
          this.selectedList.push(item);
        }
      }
    },

    /**
     * 打开类目选择
     */
    openDialog() {
      this.visible = true;
    },
    /**
     * 改变
     * @param {*} values
     */
    changeCategory() {
      this.checkedNodes = this.$refs.cascader.getCheckedNodes();
    },

    /**
     * 确定
     */
    onConfirm() {
      var list = this.checkedNodes.map((x) => x.data);

      var array = this.selectedList.map((item) => {
        return {
          value: item["id"],
          label: item["name"],
          leaf: item["is_leaf"],
          full_name: item["full_name"],
        };
      });
      list = list.concat(array);

      if (list.length <= 0) {
        this.$message({
          message: "请只少选择一条类目",
        });
        return;
      }
      this.$emit("change", list);
      this.onCancel();
    },
    onCancel() {
      this.$refs.cascader.clearCheckedNodes();
      this.selectedList = [];
      this.visible = false;
    },
  },
};
</script>

<style >
.el-cascader-panel {
  height: 300px;
}
.el-cascader-menu__wrap.el-scrollbar__wrap {
  /* height: 204px; */
  height: 100%;
}
.cate-keyword.el-select {
  display: block;
  margin-bottom: 20px;
}
.el-select-dropdown em {
  color: #f56c6c;
  font-style: normal;
}
</style>